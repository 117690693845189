div.activitiesList {
  margin-top: 10px;
}

a.activity-item {
  display: flex;
  align-items: center;
  color: $body-color;
  border-bottom: 1px solid rgba($body-color, 0.1);

  &:first-child {
    border-top: 1px solid rgba($body-color, 0.1);
  }

  &:hover {
    @if $theme == dark {
      background: linear-gradient(to right, $body-bg, $alternatingTableRowBg, $body-bg);
    } @else {
      background: linear-gradient(to right, $body-bg, #fff);
    }
  }

  div.sportType, div.competition {
    flex: 0 0 auto;
    margin: 1px 8px 0 0;
    position: relative;

    svg {
      width: 46px;
      height: 46px;
    }

    span[class*='icon-'] {
      font-size: 18px;
      position: absolute;
      left: 0;
      width: 46px;
      line-height: 46px;
    }

    span.icon-Swim { color: $swimColor; }
    span.icon-Bike { color: $bikeColor; }
    span.icon-Run { color: $runColor; }
    span.icon-Other { color: $otherColor; }
    span.icon-MultiSport {
      color: $multiSportColor;
      font-size: 16px;
    }
  }

  div.sportType.Swim  { background: radial-gradient($swimColor, rgba($swimColor,0) 50%); }
  div.sportType.Bike  { background: radial-gradient($bikeColor, rgba($bikeColor,0) 50%); }
  div.sportType.Run   { background: radial-gradient($runColor, rgba($runColor,0) 50%); }
  div.sportType.Other { background: radial-gradient($otherColor, rgba($otherColor,0) 50%); }

  @if $theme == dark {
    svg > circle {
      fill: transparent;
    }

    &.isCompleted div.sportType {
      background: radial-gradient(#fff 10px, $isCompleted 15px, rgba($isCompleted, 0) 21px);
    }
    &.isNotCompleted div.sportType {
      background: radial-gradient(#fff 10px, $isNotCompleted 15px, rgba($isNotCompleted, 0) 23px);
    }
    div.competition {
      background: radial-gradient(#fff 10px, rgba($competitionColor, 0.6) 15px, rgba($competitionColor, 0) 21px);
    }
  } @else {
    svg > circle {
      fill: #fff;
      opacity: 0.85;
    }

    &.isCompleted div.sportType {
      background: radial-gradient($isCompleted, rgba($isCompleted,0) 75%);
    }
    &.isNotCompleted div.sportType {
      background: radial-gradient($isNotCompleted, rgba($isNotCompleted,0) 75%);
    }
    div.competition {
      background: radial-gradient($competitionColor, rgba($competitionColor,0) 75%);
    }
  }

  div.activityName {
    flex: 1 1 0px;
    font-size: $font-size-sm;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div.datetime {
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
  }
}
