.l5-signup {
  & div.tiny-choice {
    font-size: $font-size-xs;
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }

  & input[type="email"], & input[type="password"] {
    width: 100%;
    height: 36px;
    font-size: 1.1rem;
  }

  & .btn:not(.btn-sm) {
    width: 100%;
    font-size: 1.1rem;
    font-weight: $font-weight-medium;

    img {
      width: 17px;
      height: 17px;
      margin-bottom: 2px;
    }
  }
  @media only screen and (max-width: 420px) {
    display: block!important;
  }
}
