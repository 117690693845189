div.setupAssistantPage {
  max-width: 650px;
  margin: 1rem auto 0;
  background-color: $card-bg;

  @if $theme == dark {
    box-shadow: 0 0 1rem rgba(#000, 0.3);
  }

  > div {
    padding: 10px;
    @if $theme == dark {
      border: 1px solid #313131;
    } @else {
      border: 1px solid #dadada;
    }
    border-top-width: 0;
  }

  // header bar
  > div:first-child {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: #313131;
    border: 1px solid #313131;
    border-bottom-width: 0;
    color: white;
    text-transform: uppercase;

    > img {
      width: 26px;
      height: 26px;
      margin: 3px 8px 3px 3px;
    }


  }

  h4 {
    margin: 0.5rem 0 1rem;
  }

  div.l5-separator {
    display: flex;
    align-items: center;
    margin: 1.5rem 0 0.25rem;

    > div {
      flex: 0 0 auto;
      opacity: 0.75;
      text-transform: uppercase;
      font-size: $font-size-xs;

      &.icon {
        margin-right: 4px;
        line-height: 1;
      }

      &.hline {
        flex: 1 1 0px;
        margin-left: 8px;
        height: 1px;
        background-color: #cfcfcf;
      }
    }
  }

  div.external-connections {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -100px;
    margin-top: -1rem;

    > img {
      margin-left: 100px;
      margin-top: 1rem;
      width: 50px;
      height: 50px;
      cursor: pointer;
    }

    @media (max-width: 499px) {
      margin-left: -50px;

      > img { margin-left: 50px; }
    }
  }
}
