.l5-reset-password {
  & .btn:not(.btn-sm) {
    min-width: 215px;
    font-size: 1.1rem;
    font-weight: $font-weight-medium;
  }
  & h5.text-center {
    background-color: transparent;
  }
}

div.standalone-content h5 {
  background-color: $body-bg;
}