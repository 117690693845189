table.trainingTotals, table.trainingTotalsDetails {
  span.icon-Swim { color: $swimColor; }
  span.icon-Bike { color: $bikeColor; }
  span.icon-Run { color: $runColor; }
  span.icon-Other { color: $otherColor; }

  font-size: $font-size-sm;
  @include media-breakpoint-down(sm) {
    font-size: 11px;
  }

  @include user-select;
  width: 100%;
  table-layout: fixed;
  margin: 0.5rem auto;

  th {
    &:first-child {
      font-weight: 400; // icon
    }
    line-height: 22px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 2px;
  }

  tr {
    &:nth-child(even) {
      background-color: $alternatingTableRowBg;
    }

    td {
      text-align: right;
      padding-right: 0.5rem;

      &:first-child {
        text-align: left;
        padding-left: 4px;
        padding-right: 0;
      }

      &:not(:first-child):not(:last-child) {
        border-right: solid 1px #c0bfbf;
      }
    }
  }
}

table.trainingTotals span[class*='icon-'] { font-size: 18px; }
table.trainingTotalsDetails span[class*='icon-'] { font-size: 22px; }

table.trainingTotals tr {
  // fixed width for 1st column
  th:first-child {
    width: 32px;
  }

  // 2nd last row (totals)
  &:nth-last-child(2) {
    td:not(:first-child) {
      border-top: 1px solid #c0bfbf;
    }
  }

  // last row (charts)
  &:last-child {
    background-color: inherit;

    td {
      padding: 2em 0 0 0;
      text-align: center;

      &:not(:first-child):not(:last-child) {
        border-width: 0;
      }
    }
  }
}

div.sportTotals {
  margin: 2rem 0 0 4px;
}