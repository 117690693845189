.react-grid-layout {
  overflow: hidden;
}

.react-grid-item {
  box-sizing: border-box;
  overflow: auto;
  padding: 8px;
  border: 1px solid $calendarBorderColor;
  touch-action: auto !important;
  background-color: $card-bg;

  @if $theme == dark {
    box-shadow: 0 0 1rem rgba(#000, 0.3);
  }

  & > :first-child {
    height: 100%;
  }

  .react-resizable-handle::after {
    border-color: rgba($highContrastColor, 0.5);
  }
}

.dashboard-tile-header {
  font-weight: $font-weight-semibold;
  text-transform: uppercase;
  font-size: $font-size-sm;
  color: $body-color;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.dashboardSettings {
  & div.react-select {
    min-width: 100px;
    max-width: 320px;
  }

  & #quickDateRangeButton {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

div.CPFTPs {
  position: absolute;
  right: 4px;
  background: $body-bg;
  padding: 2px 4px;
  font-size: $font-size-xs;

  @if $theme == dark {
    border: 1px solid rgba(#000, 0.2);
    box-shadow: 0 0 0.5rem rgba(#000, 0.2);
  } @else {
    border: 1px solid darken($body-bg, 3%);
  }

  td:nth-child(2) {
    text-align: right;
  }
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75%;
}

.draggableHandle {
  i {
    color: $infoColor;
    padding: 0 10px 0 5px;
    font-size: 12px;
  }
  touch-action: none;
  cursor: grab;
  &:active {
    cursor: grabbing
  }
}