div.seasonPlanBar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  div.seasonRangesContainer {
    background-color: $card-bg;
  }

  &.seasonPlanBar-Ultimate {
    background-color: $card-bg;
    border-top: 1px solid $calendarBorderColor;
  }
}

div.mesoPlanOverview {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  height: 24px;
  align-items: flex-end; // align to bottom

  @include media-breakpoint-down(lg) {
    height: 18px;

    div.linkContainer {
      line-height: 18px;
    }
  }
}


div.mesoPlanBar, div.seasonPlanBar div.seasonRanges {
  margin-right: 2px;

  @include media-breakpoint-down(lg) {
    margin-right: 1px;
  }
}


div.mesoPlanBar {
  cursor: pointer;
  flex: 1 1 0px;
  height: 75%;
  transition: height 0.2s ease;

  &.highlighted, &:hover {
    height: 100%;
  }

  @if $theme == dark {
    &.inThePast {
      filter: brightness(55%) grayscale(50%);
    }
  } @else {
    &.inThePast {
      filter: brightness(150%) grayscale(67%);
    }
    &.undefinedWeek.inThePast {
      filter: brightness(105%) grayscale(67%);
    }
  }
}

div.mesoPlanBarTooltipSwitch {
  display: flex;
  margin-top: 0.5em;
  height: 2em;
  margin-left: -0.5em;

  > div {
    margin-left: 0.5em;
    flex: 1 1 0px;
    cursor: pointer;
  }
}

.trainingWeek {
  border: 1px solid darken($trainingWeekColor, 5%);
  background: linear-gradient(to top, $trainingWeekColor, lighten($trainingWeekColor, 20%));
}

.recoveryWeek {
  border: 1px solid darken($recoveryWeekColor, 5%);
  background: linear-gradient(to top, $recoveryWeekColor, lighten($recoveryWeekColor, 20%));
}

.taperingWeek {
  border: 1px solid darken($taperingWeekColor, 5%);
  background: linear-gradient(to top, $taperingWeekColor, lighten($taperingWeekColor, 20%));
}

.undefinedWeek {
  border: 1px solid darken($secondary, 10%);
  @if $theme == dark {
    background: linear-gradient(to top, #555, #666);
  } @else {
    background: linear-gradient(to top, darken($secondary, 5%), lighten($secondary, 5%));
  }
}
