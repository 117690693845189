div.notificationsList {
  width: 25rem;
  max-height: 90vh;
  overflow: auto;
  padding: 2px 0; // no idea why, but required to prevent the vertical scrollbar from always appearing
}

div.notificationItem {
  &.unread {
    background: linear-gradient(to right, rgba($highlightedBg, 0.5), rgba($highlightedBg, 0));
  }

  &:hover {
    @include gradient-bg($dropdown-link-hover-bg);
    color: color-yiq($dropdown-link-hover-bg);
  }

  /* horizontal border line inbetween consecutive notification items */
  & + &, & + div.nextNotifications {
    border-top: 1px solid $hr-border-color;
  }

  a {
    color: inherit;
  }
}

div.nextNotifications {
  text-align: center;
}

div.notificationItemContent {
  font-size: $font-size-sm;
  padding: 0.25em 0.5em;
}

div.notificationTimestamp {
  opacity: 0.5;
  font-size: $font-size-xs;
}

@include media-breakpoint-down(sm) {
  div.notificationsList {
    width: 18rem;
    max-height: 80vh;
  }
}

span.notificationClickable {
  text-decoration: underline;
  cursor: pointer;
}
