@mixin no-user-select {
  user-select: none;
}

@mixin user-select {
  user-select: text;
}

@media only screen {
  .printView {
    display: none;
  }
}

$trainingWeekColor: #009ed9;
$recoveryWeekColor: #4eb32d;
$taperingWeekColor: #f7eb62;

$swimColor: #009ed9;
$bikeColor: #4eb32d;
$runColor: #e64b78;
$otherColor: #4e4e4e;
@if $theme == dark {
  $otherColor: #999999;
}
$competitionColor: #f5ce23;
$multiSportColor: #035779;

:root {
  --swim-color: #{$swimColor};
  --bike-color: #{$bikeColor};
  --run-color: #{$runColor};
  --other-color: #{$otherColor};
  --competition-color: #{$competitionColor};
  --multisport-color: #{$multiSportColor};
}

$infoColor: $body-color;

$calendarBorderColor: rgba($highContrastColor, 0.175);

$activityItemBg: #d9e3e8;
$activityItemBorderColor: $activityItemBg;
@if $theme == dark {
  $activityItemBg: #606060;
  $activityItemBorderColor: darken($activityItemBg, 20%);
}

$alternatingTableRowBg: #edf6f9;
@if $theme == dark {
  $alternatingTableRowBg: rgba(#000, 0.15);
}

// availabilities
$notAvailable: #f7f7f7;
$available: #e8e8e8;
@if $theme == dark {
  $notAvailable: $body-bg;
  $available: $secondary;
}

$ratingRed: #f15959;
$ratingYellow: #ffbb00;
$ratingGreen: #90d465;

.rating-red { color: $ratingRed; }
.svg-red { fill: $ratingRed; filter: url('#dropshadow') }
.svg-red-disabled { fill: darken($ratingRed, 15%); }
.svg-red-hover:hover { fill: $ratingRed; filter: brightness(1); }

.rating-yellow { color: $ratingYellow; }
.svg-yellow { fill: $ratingYellow; filter: url('#dropshadow') }
.svg-yellow-disabled { fill: darken($ratingYellow, 5%); }
.svg-yellow-hover:hover { fill: $ratingYellow; filter: brightness(1); }

.rating-green { color: $ratingGreen; }
.svg-green { fill: $ratingGreen; filter: url('#dropshadow') }
.svg-green-disabled { fill: darken($ratingGreen, 15%); }
.svg-green-hover:hover { fill: $ratingGreen; filter: brightness(1); }
