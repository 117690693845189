div.seasonGoals {
  position: relative;
  margin: 0 0.5rem;

  > div.flex-row {
    align-items: center;
    padding: 0.125em 0.5em;

    &:nth-child(even) {
      background-color: $alternatingTableRowBg;
    }

    > div {
      padding-left: 0.5em;
    }

    > div:first-child {
      padding-left: 0;

      span[class*='icon-Competition'] {
        font-size: 18px;
      }
    }

    > div:nth-child(2) {
      flex: 1 1 0px;
    }
  }
}

div.trainingPlanSettings {
  margin: 0 0.5rem;
}

ul.errorList, ul.warningList {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    margin-left: 32px;
    padding-left: 0;

    &:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 16px;
    }

    > ul {
      list-style: none;
      padding-left: 1em;
    }
  }
}

ul.errorList > li:before {
  content: '\f057'; // width is 16px
  color: $danger;
  margin-left: -31px;
  margin-right: 14px;
}

ul.warningList > li:before {
  content: '\f071'; // width is 18px
  color: $warning;
  margin-left: -32px;
  margin-right: 13px;
}
