div.seasonRanges {
  min-height: 1.5rem;
  // make sure a 2nd level is always visible (at least partially), because the seasonRangesContainer height is limited to 50vh, see below
  max-height: calc(50vh - 1.5rem - 2px);

  div.seasonPlanPlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 1.5rem;
    @if $theme != dark {
      color: $gray-600;
    }
    font-size: $font-size-sm;
    pointer-events: none;
    touch-action: none;
  }

  div[class*='seasonRangeBox'] {
    $phaseColor: #05628a;
    border-right: 1px solid $card-bg;
    @if $theme == dark {
      background-color: #555;
    }

    &.seasonRangeBox { box-shadow: inset 0 0 2px 2px darken($phaseColor, 3); }

    &.seasonRangeBox-MacroPhase1 { box-shadow: inset 0 0 2px 2px lighten($phaseColor, 8); }
    &.seasonRangeBox-MacroPhase2 { box-shadow: inset 0 0 2px 2px lighten($phaseColor, 4); }
    &.seasonRangeBox-MacroPhase3 { box-shadow: inset 0 0 2px 2px $phaseColor; }
    &.seasonRangeBox-MacroPhase4 { box-shadow: inset 0 0 2px 2px lighten($phaseColor, 12); }

    &.seasonRangeBox-MesoTrainingPhase1, &.seasonRangeBox-MesoTrainingPhase2, &.seasonRangeBox-MesoTrainingPhase3 {
      background: linear-gradient(to top, $trainingWeekColor, lighten($trainingWeekColor, 20%));
    }
    &.seasonRangeBox-MesoRecoveryPhase, &.seasonRangeBox-RecoveryPhase {
      background: linear-gradient(to top, $recoveryWeekColor, lighten($recoveryWeekColor, 20%));
    }
    &.seasonRangeBox-TaperPhase {
      background: linear-gradient(to top, $taperingWeekColor, lighten($taperingWeekColor, 20%));
    }

    &.inThePast {
      filter: brightness(150%) grayscale(67%);
    }

    i.fa-bars {
      margin-left: 0.5em;
      cursor: pointer;
      font-size: $font-size-sm;
    }

    input[type="text"], textarea {
      padding: 1px 2px;
      outline: 0;
      @if $theme == dark {
        background-color: transparent;
        box-shadow: none;
        border-color: $body-bg;
      }
    }
  }
}

div.withSeasonRanges {
  > div.noPermissionPlaceholder {
    background-color: $card-bg;
    text-align: center;
    @if $theme != dark {
      color: $gray-600;
    }
    font-size: $font-size-sm;
  }

  div.seasonRangesContainer {
    flex: 1 1 0px;
    max-height: 50vh;
    overflow-y: auto;
  }

  div.withSeasonRanges-rhsButtonArea {
    flex: 0 0 24px; // width
    background-color: $card-bg;
    line-height: 1;
    display: flex;
    justify-content: center; // center content horizontally
    @if $theme != dark {
      color: $gray-600;
    }

    i:hover {
      color: $ctaColorHover;
    }
  }
}