div.TPFilter {
  font-weight: $font-weight-semibold;
  font-size: 18px;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;

  span {
    padding: 0 10px 3px 10px;
    cursor: pointer;
    
  }
  span.selected {
    color: #fff;
    background: rgb(77, 77, 77);
  }
}

div.staticTrainingPlansOverview {
  justify-content: center;

  & > a {
    flex: 0 1 400px;
    min-width: 300px;
    color: $body-color;
    margin: 1rem;

    &:hover {
      box-shadow: 0px 0px 20px -4px rgba(87,87,87,1);
      transition: box-shadow 0.5s;
    }

    .card {
      height: 100%;
    }

    img {
      max-width: 100%;
    }
    
    div.card-body {
      display: flex;
      flex-direction: column;
      >p {
        display: none;
      }
      >p:first-of-type {
        display: -webkit-box; // for line-clamp
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical; // required for ellipsis to show up
        overflow: hidden;
        text-overflow: ellipsis;
      }

      >p:last-of-type {
        margin-top: auto;
        box-shadow: 0px 2px 10px -4px rgba(87,87,87,1);
        display: block;
      }
    }
  }
}

div.card.plansDescription {
  text-align: justify;
  margin: 1rem auto;

  @include media-breakpoint-down(sm) {
    max-width: 95%;
    .plansFAQ {
      column-count: 1;
    }
  }
  @include media-breakpoint-up(sm) {
    max-width: 95%;
    .plansFAQ {
      column-count: 2;
      column-gap: 2em;
    }
  }
  @include media-breakpoint-up(md) {
    max-width: 85%;
    .plansFAQ {
      column-count: 3;
      column-gap: 2em;
    }
  }
  @include media-breakpoint-up(xl) {
    max-width: 85%;
    .plansFAQ {
      column-count: 4;
      column-gap: 2em;
    }
  }
}

div.staticTrainingPlan {
  & > div {
    max-width: 768px;
    margin: 0 auto;

    img {
      max-width: 100%;
    }
  }

  div#goBackLink {
    margin-bottom: 0.25rem;

    a {
      color: $body-color;
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;

      i {
        margin-right: 0.5rem;
      }
    }
  }

  div.staticTrainingPlanBuySettings {
    display: flex;
    flex-direction: column;
    align-items: center;

    table {
      margin-top: 0.25;

      td {
        padding: 0.25em;

        &:first-child {
          font-weight: $font-weight-bold;
        }
      }
    }
  }

  a#haveCoupon {
    margin-top: 1rem;
  }
  input#couponInput {
    max-width: 8rem;
  }

  button#buyButton {
    margin-top: 1rem;
  }

  table.missingParamsTable tbody tr>td {
    @include media-breakpoint-up(md) {
      padding-left: 1rem;
    }
    @include media-breakpoint-down(md) {
      padding-left: 5px;
    }
  }
}

div.staticTrainingPlanPurchased {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div.alert-success {
    padding: 1rem;
    background: #fff;
    color: #000;
    min-width: 300px;
    display: flex;
    align-items: center;
    border: 3px solid rgba($success, 0.8);

    i.fa-check {
      color: $success;
      font-size: 2rem;
      margin-right: 1rem;
    }

    a {
      color: $success;
      font-weight: $font-weight-medium;
    }
  }
}
