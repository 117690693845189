.btn {
  white-space: nowrap;
}

button {
  color: inherit;
}

button:focus {
  outline: 0;
}

@mixin button {
  border-radius: $border-radius;
  min-width: 100px;
  cursor: pointer;
  font-weight: $btn-font-weight;
}

// set min width and cursor
button.btn {
  @include button
}
button.btn.disabled {
  cursor: not-allowed;
}
button.btn-link {
  min-width: inherit;
  box-shadow: none !important;
  padding: 0;
}

@mixin primary-button {
  background: $ctaColorHover;
  font-weight: $btn-font-weight;
  border-color: $ctaColor;
  color: #fff;

  &:hover {
    background: $ctaColor;
    color: #fff;
  }
  &:focus {
    border-color: $ctaColor !important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .dropdown.show > & {
    background-color: $ctaColor;
    color: #fff;

    &:focus {
      box-shadow: none;
    }
  }
}

// our primary buttons are special, with custom background gradient
button.btn.btn-primary {
  @include primary-button
}

// use a horizontal margin inbetween consecutive buttons
.btn + .btn {
  margin-left: 1rem;
}

// use a custom darker border color for secondary buttons
.btn-secondary {
  @if $theme == dark {
    @include button-variant($secondary, #000, darken($secondary, 10%), #000, darken($secondary, 10%), #000);
  } @else {
    @include button-variant(#eff3f6, rgba(27, 31, 35, 0.2), #e6ebf1, rgba(27, 31, 35, 0.35), #e9ecef, rgba(27, 31, 35, 0.35));
    background: #eff3f6 linear-gradient(-180deg, #fafbfc, #eff3f6 90%) repeat-x;

    &:hover {
      background: #e6ebf1 linear-gradient(-180deg, #f0f3f6, #e6ebf1 90%) repeat-x;
    }
  }

  &:focus,
  &.focus {
    box-shadow: none!important;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .dropdown.show > & {
    box-shadow: none;

    &:focus {
      box-shadow: none!important;
    }
  }
}

.btn-group.singleSelectionInput {
  // Use the primary color for background of active secondary buttons
  // and `highlightedBg` for the hovered background color.
  > .btn-secondary {
    @if $theme == dark {
      @include button-variant($secondary, #000, $highlightedBg, #000, $primary, #000);
    } @else {
      @include button-variant($secondary, $secondary, $highlightedBg, $highlightedBg, $primary, $primary);
    }

    // Don't care about the disabled flag when styling active buttons (e.g., read-only SingleSelectionInput).
    &.active {
      color: #fff;
      background-color: $primary;
      background-image: none;
      border-color: $primary;
    }
  }

  // A button group with additional class `light` uses buttons with a default white background color.
  &.light > .btn-secondary {
    @if $theme == dark {
      border-color: transparent !important;
    } @else {
      @include button-variant(#fff, #fff, $highlightedBg, $highlightedBg, $primary, $primary);
      color: $body-color;
    }
  }

  > .btn {
    border: 0;
    border-radius: 2px;
    box-shadow: none;
    transition: color 0s; // prevent text color transitions (black <-> white)
    min-width: 32px;
    min-height: 32px;
    font-weight: $font-weight-normal;
  }
  > .btn + .btn {
    margin-left: 2px;
  }
}

// we need to duplicate some button-group styles from Bootstrap, as we're disabling `enable-rounded`...
.btn-group:not(.singleSelectionInput) {
  > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .btn.dropdown-toggle-split {
    min-width: auto;
  }
}

// define a custom 'light' and 'light-ctaColor' button
.btn-light {
  @include button-variant(transparent, transparent, transparent, transparent, rgba(#000, 0.05), transparent);
  box-shadow: none !important;
  color: $body-color !important;
}

.btn-light-ctaColor {
  @include button-variant(transparent, transparent, transparent, transparent, transparent, transparent);
  box-shadow: none !important;
  color: $ctaColor !important;
  height: 24px!important;
  line-height: 100%;
  border-radius: 11px!important;
  border: 1px solid $ctaColor !important;

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:hover {
    color: $ctaColorHover !important;
    border: 1px solid $ctaColorHover !important;
  }
}

@media only screen {
  button.btn-toggle {
    font-size: $font-size-xs;
    font-weight: $btn-font-weight;
    height: 22px;
    line-height: 100%;
    border-radius: 11px;
    border: 1px solid rgba($body-color, 0.5);
    color: rgba($body-color, 0.5);
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: none !important;

    &:hover {
      color: rgba($body-color, 0.75);
      border-color: rgba($body-color, 0.75);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $ctaColor;
      border-color: $ctaColor;

      &:hover {
        color: $ctaColorHover;
        border-color: $ctaColorHover;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100px;
    }
  }
}

// disable background-image (incl. background gradients) transitions
// as it's set to none if :active (or .active), leading to ugly flickering
.btn {
  transition: background-image 0s;
}
