$menuTextColor: #f7f7f7;
$menuHeight: 46px;

.navbar {
  background: #313131;
  padding: 0 2rem;
  height: $menuHeight;
  flex-wrap: nowrap;

  // reduce horizontal padding for narrow views
  @include media-breakpoint-down(md) {
    padding: 0 1rem;
  }

  // logo
  .navbar-brand img {
    height: 28px;
  }

  ul.navbar-nav {
    flex-direction: row;
    flex-wrap: nowrap;
    height: $menuHeight;

    // Hide the items of the first navbar (news, blog, feedback) for mobiles.
    // We cannot hide the whole navbar, we need its `auto` right margin.
    @include media-breakpoint-down(sm) {
      &.news-blog-feedback > li.nav-item {
        display: none;
      }
    }

    li.nav-item {
      padding: 0 1rem;
      line-height: $menuHeight;

      @include media-breakpoint-down(md) {
        padding: 0 0.75rem;
      }

      > a, a.nav-link {
        height: $menuHeight; // somehow a bit higher by default
      }

      a.nav-link {
        padding: 0; // remove vertical padding

        > i.far, > i.fas {
          line-height: inherit;
        }
      }

      > a, > .dropdown > a {
        display: inline-block;
        color: $menuTextColor;
        font-size: 0.9375rem; // ~14px
        text-decoration: none;
        white-space: nowrap;
      }

      // active dashboard/calendar links
      a.active-nav-link {
        position: relative;

        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 4px;
          background-color: $primary;
        }
        &.premiumLink:after {
          visibility: hidden;
        }
      }
    }
  }

  .dropdown-menu {
    // remove styles inherited from li.nav-item parents
    line-height: $line-height-base;

    #submenuTraining, #submenuSettings {
      background: transparent;
      box-shadow: none;

      svg, i { margin-left: -4px; }
      @media (min-width: 510px){ padding-left: 7px; }
      @media (max-width: 510px){ padding-left: 14px; }

      &:hover, &:active {
        background: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
      }
    }

    &.athletemenu, &.trainermenu {
      .dropdown-toggle {
        border-radius: 0;
      }
      .dropdown-item {
        cursor: pointer;
        padding: 7px 18px;

        span[class*='icon-'], i.far, i.fas, svg {
          display: inline-block;
          width: 32px;
          margin-left: -8px;
          text-align: center;
        }
      }
      .dropdown-divider {
        margin: 0.25rem 0;
      }
    }
  }

  & div.news.dropdown-menu.show {
    width: 350px;
    padding-top: 6px;
    max-height: 80vh;
    overflow: auto;
    & button.disabled.dropdown-item {
      white-space: normal;
      padding: 3px 16px;
      color: $highContrastColor;
    }
    & span.newsTag {
      color: #fff;
      border-radius: 15px;
      padding: 2px 7px;
      font-size: $font-size-xs;
      font-weight: $font-weight-semibold;
      text-transform: capitalize;
    }

    & span.newsTitle {
      font-weight: $font-weight-semibold;
      margin-left: 5px;
    }

    & div.newsContent {
      font-weight: $font-weight-normal;
      font-size: $font-size-sm;
      padding-top: 2px;
      margin-bottom: 3px;
    }
  }
}

.trainerView .navbar {
  div#trainerView {
    color: $menuTextColor;
  }
}

span.button_badge {
  display: inline-block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: $primary;
  position: absolute;
  top: 6px;
}

span.button_badge_num {
  width: 14px;
  height: 14px;
  margin-left: -3px;
  top: 6px;
  color: black;
  padding: 0 5px 0 4px;
  font-size: 11px;
  line-height: 14px;
}

div.modal {
  text-align: center; // required for horizontally centered nested modal-dialogs with `display: inline-block`
}

div.modal-dialog {
  text-align: initial;

  &.SetupAssistant {
    max-width: 650px;
    margin: 30px auto 0 auto;
  }
  &.AthleteProfile {
    max-width: 780px;
    margin: 30px auto 0 auto;

    & input::placeholder {
      font-style: italic;
    }
    textarea#comment {
      width: 100%;
    }

  }
  &.TrainerProfile {
    max-width: 650px;
    margin: 30px auto 0 auto;
  }
  &.AccountSettings {
    max-width: 760px;
    margin: 30px auto 0 auto;
  }
  &.FeedbackForm {
    max-width: 600px;
    margin: 30px auto 0 auto;
  }
  &.CurrentUserAvailableTimeSlots {
    max-width: 700px;
    margin: 30px auto 0 auto;
  }
  &.SwitchProductPlan {
    max-width: none;
    min-width: 500px;
    display: inline-block;
  }
  &.TrainingPlan {
    max-width: 650px;
  }
  &.WorkoutEditor {
    max-width: 70em;
    .modal-content {
      min-height: 15rem;
      @media (max-width: 650px){ min-height: 18rem; }
      @media (max-width: 600px){ min-height: 22rem; }
      @media (max-width: 520px){ min-height: 26rem; }
      @media (max-width: 500px){ min-height: 30rem; }
    }
  }
}

.premiumLink {
  i.far.fa-star {
    margin-left: 4px;
    transition: transform .8s ease-in-out;
  }

  &:hover {
    i.far.fa-star {
      transform: rotate(215deg);
    }
  }

  div {
    padding-top: 5px;
    line-height: 145%;
  }
}

.fileUpload {
  .dropdown-menu {
    max-height: 50vh;
    overflow-y: auto;
    padding: 8px 3px;
  }

  i.fas.fa-check:before {
    margin-right: 10px;
    color: $isCompleted;
  }
  i.fas.fa-times:before {
    margin-right: 10px;
    color: $isNotCompleted;
  }
}

nav.navbar .premiumLink {
  @media (max-width: 499px){
    display: none!important;
  }
}

.criticalWarning {
  color: $body-color;
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translate(-50%, 0);
  min-width: 200px;
  z-index: 100;

  @include media-breakpoint-down(md) {
    width: 90%;
  }

  @include media-breakpoint-down(lg) {
    width: 70%;
  }
  
  @include media-breakpoint-down(xl) {
    max-width: 50rem;
  }

  div.closeIcon {
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
  }
  @if $theme == dark {
    color: $body-bg;
  }
}

div.referralBox {
  padding: 0.5rem;
  font-size: $font-size-sm;
  min-width: 21em;
  white-space: pre-wrap;

  input {
    margin-top: 0.7rem;
    width: 100%;
  }

  div.send-button {
    text-align: right;
    margin-top: 0.5rem;
  }
}