div.trainerDashboard {
  > div.card {
    display: block; // not flex
  }

  div.invitations {
    margin-bottom: 10px;

    // some vertical spacing inbetween invitations
    table {
      border-collapse: separate;
      border-spacing: 0px 0.5rem;
    }

    @media (max-width: 399px) {
      div.invitationButtons {
        display: block; // stack the buttons vertically
      }
    }
  }

  div.trainee-invitation {
    margin-top: 10px;
  }
}

div.weeksSelection {
  display: flex;
  align-items: center;
  justify-content: center;

  > div:first-child, > div:last-child {
    padding: 0 0.5rem;
    cursor: pointer;
  }

  > div:nth-child(2) {
    margin: 0 0.5em;
    font-size: 1.1rem;
    font-weight: $font-weight-medium;
  }
}

table.traineesGrid {
  width: 100%;
  border-collapse: separate;
  border-spacing: 1px 2px;

  td {
    vertical-align: top;

    &.displayName {
      cursor: pointer;
      padding: 0 1rem 0 0.5rem;
      white-space: nowrap;
      overflow: visible;
      line-height: 32px; // match avatar height

      span.displayName {
        margin-right: 0.25rem;
        color: inherit;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 0.5rem 0 0;

        span.displayName {
          display: none;
        }
      }
    }

    // meso weeks / season ranges
    &:last-child {
      width: 100%;
    }
  }

  div.mesoPlanWeeks {
    display: flex;
    position: relative;

    > div {
      flex: 1 1 0px;
      overflow: hidden;
      text-align: center;

      &.todo {
        position: relative;
        z-index: 1;

        @if $theme != dark {
          // make the cell *background* lighter, but keep the cell contents (e.g., competition icon) fully opaque
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(#fff, 0.625);
            z-index: -1;
          }

          // lighter border colors
          &.trainingWeek { border-color: rgba($trainingWeekColor, 0.5); }
          &.recoveryWeek { border-color: rgba($recoveryWeekColor, 0.5); }
          &.taperingWeek { border-color: rgba($taperingWeekColor, 0.5); }
        } @else {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(#000, 0.35);
            z-index: -1;
          }

          &.trainingWeek { border-color: darken($trainingWeekColor, 10%); }
          &.recoveryWeek { border-color: darken($recoveryWeekColor, 10%); }
          &.taperingWeek { border-color: darken($taperingWeekColor, 35%); }
        }
      }

      &.today {
        @if $theme == dark {
          filter: drop-shadow(1px 1px 3px #000);
        } @else {
          border-color: #4e4e4eb6;
          filter: drop-shadow(2px 2px 2px #4e4e4eb6);
        }
      }

      span[class*='icon-Competition'] {
        line-height: 30px; // for proper vertical centering
        font-size: 20px;
      }

      &.withCompetitions {
        position: relative;
        z-index: 1;

        // some light radial gradient as background for the competition icon
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: radial-gradient(#fff 33%, rgba(#fff, 0%) 100%);
          z-index: -1;
        }

        // adjust the stop color for todo weeks, because .todo:before is overridden
        &.todo:before {
          @if $theme == dark {
            background: radial-gradient(rgba(#fff, 0.8) 33%, rgba(#000, 0.3) 100%);
          } @else {
            background: radial-gradient(#fff 33%, rgba(#fff, 0.625) 100%);
          }
        }
      }

      a.calendarLink {
        display: block;
        height: 30px;
        color: inherit;
      }
    }
  }

  div.mesoPlanWeeks > div, div.seasonRanges {
    margin-right: 1px;
  }

  div.monthsHeaders {
    position: relative;
    overflow: hidden;
    height: 1.5em;
    margin-right: 24px; // must match width of WithSeasonRange's rhs button area

    > div {
      position: absolute;
      top: 0;
    }
  }
}

div.traineeDetails {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-top: calc(2px - 0.5rem);

  @media (max-width: 800px) {
    display: block;
  }

  > div {
    margin-left: 1rem;
    margin-top: 0.5rem;
    padding: 10px;
    @include media-breakpoint-up(sm) {
      box-shadow: 0px 0px 10px #00000020;
    }
  }

  //connection
  > div:first-child {
    flex: 1 2 300px;
    max-width: 600px;
  }

  // athlete profile
  > div:last-child {
    flex: 2 1 300px;
    min-width: 600px;
    max-width: 800px;
    align-self: start;

    @media (max-width: 650px) {
      min-width: 0px;
    }
  }
}
