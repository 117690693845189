div.l5c-field-row {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center; // center vertically

  // margin between consecutive field rows
  & + & {
    margin-top: 0.75em;
  }

  // set box-sizing for all children
  & * {
    box-sizing: border-box;
  }

  // fixed-size label
  & > div:first-child {
    flex: 0 0 auto;
  }

  // growing/shrinking content
  & > div:last-child {
    flex: 1 1 0px;
  }
}
