// Customize a few variables from 'node_modules/bootstrap/scss/_variables.scss'
// and include the Bootstrap SCSS files.
$primary: #ffdf09;
$secondary: #ededed;
$body-bg: #f4f4f4;
$body-bg-transparent: #f4f4f4F0;
$body-color: #505050;
$yiq-contrasted-threshold: 161; // controls foreground color (light/dark) depending on background color

// PerfectPace:
$highlightedBg: lighten($primary, 20%);
$highContrastColor: #000; //color-yiq($body-bg, #000, #fff);
$ctaColor: #f65801;
$ctaColorHover: lighten($ctaColor, 10%);

$box-shadow:    0 .5rem 1rem rgba(#000, .15);
$box-shadow-sm: 0 .125rem .25rem rgba(#000, .075);
$box-shadow-lg: 0 1rem 3rem rgba(#000, .175);
$dropdown-box-shadow: 0 .5rem 1rem rgba(#000, .175); // needed for some reason

$enable-rounded: false;
$enable-shadows: true;
$enable-gradients: true;

$link-color: $ctaColor;
$link-hover-color: $ctaColorHover;
$link-hover-decoration: none;

$border-radius: 4px;
$border-radius-sm: 2px;

$font-family-sans-serif: -apple-system, BlinkMacSystemFont, Arial, sans-serif;
$font-size-sm: 0.875rem;
$font-size-xs: 12px; // PerfectPace addition
$font-weight-normal: 400;
$font-weight-medium: 500; // PerfectPace addition
$font-weight-semibold: 600; // PerfectPace addition
$font-weight-bold: 700;
$btn-font-weight: 400;
$headings-font-weight: $font-weight-normal;

$hr-border-color: rgba($highContrastColor, 0.1);

$input-btn-padding-y: 0.25rem; // ~4px
$input-btn-padding-x: 0.5rem;  // ~8px

$input-btn-focus-width: 1px;

$input-btn-padding-y-sm: 0.1875rem; // ~3px
$input-btn-padding-x-sm: 0.375rem; // ~6px

$input-font-weight: 400;
$input-border-radius: 0;
$input-bg: #fff;
$input-color: $highContrastColor;
$input-border-color: rgba(#000, 0.1);

$dropdown-bg: #fff;
$dropdown-border-color: rgba(#000, 0.15);
$dropdown-divider-bg: $hr-border-color;
$dropdown-padding-y: 0;
$dropdown-link-color: $body-color;
$dropdown-link-hover-bg: $highlightedBg;
$dropdown-link-active-bg: $primary;
$dropdown-link-disabled-color: rgba($body-color, 0.5);
$dropdown-item-padding-y: 0.2rem;
$dropdown-item-padding-x: 1rem;

$nav-link-padding-x: 0.5rem; // ~8px

$alert-margin-bottom: 0; // default: 1rem

$popover-max-width: inherit; // default: 276px

$card-border-width: 0;
$card-border-color: rgba($body-color, 0.5);
$card-bg: #fff;
$card-cap-bg: $card-bg;
$card-spacer-x: 0;
$card-spacer-y: 0;

$btn-box-shadow: inset 0 1px 0 rgba(#fff, .15), 1px 1px 2px rgba(#000, .1);

$isNotCompleted: #f1597a; // PerfectPace
$isCompleted: #90d465; // PerfectPace
$isCompletedOff: #ffdf09; // PerfectPace
$red: $isNotCompleted;
$green: $isCompleted;

$popover-bg: $dropdown-bg;

$modal-header-padding: 0.5rem 1rem;
$modal-content-bg: #fff;

$alert-bg-level: -10;
$alert-border-level: -9;

$nav-tabs-border-color: $hr-border-color;
$nav-tabs-link-active-color: $body-color;
$nav-tabs-link-active-bg: #fff;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-link-active-bg;

@import "node_modules/bootstrap/scss/bootstrap";

.navbar-nav .dropdown-menu {
  position: absolute; // Bootstrap (v4.3.1) uses `position: static` here - bug?
}
