div.react-select {
	@include box-shadow($btn-box-shadow);
	min-width: 300px;

	& input {
		box-shadow: none !important;
	}
}

div.react-select__control {
	border-radius: 0;
	border: 0;
	box-shadow: none;
	min-height: 32px;
	background-color: $input-bg;
	color: color-yiq($input-bg);
}

div.react-select__placeholder {
	white-space: nowrap;
}

div.react-select__value-container:not(.react-select__value-container--is-multi) {
	padding-top: 0;
	padding-bottom: 0;
}

div.react-select__value-container--is-multi {
	padding-left: 2px;
	padding-right: 0;
}

div.react-select__indicators {
	padding: 0 8px;
}

div.react-select__indicator {
	padding: 0;
}

span.react-select__indicator-separator {
	display: none;
}

div.react-select__menu {
	border-radius: 0;
	@if $theme == dark {
		border: 1px solid $dropdown-border-color;
		border-top-width: 0;
	}
	margin: 0;
	z-index: 1000;
	background-color: $input-bg;
	@include box-shadow($dropdown-box-shadow);
}

div.react-select__menu-list {
	padding: 0;
}

div.react-select__option {
	background-color: transparent;

	&:hover, &--is-focused {
		background-color: $highlightedBg;
		color: color-yiq($highlightedBg);
	}
	&--is-selected, &--is-selected:hover {
		background-color: $primary;
		color: color-yiq($primary);
	}
}

div.react-select__single-value {
	color: $body-color;
}

div.react-select__multi-value {
	background-color: $body-bg;
	height: 27px;

	&__label {
		color: $body-color;
		font-size: $font-size-sm;
		align-self: center;
		margin-bottom: 1px; // to fix vertical centering
		padding-top: 0;
		padding-bottom: 0;
	}
}
