.availability, .CurrentUserAvailableTimeSlots {
  // tabs for sport type selection
  ul.nav-tabs {
    margin-bottom: 10px;

    > li.nav-item {
      flex: 1 1 0px;
      text-align: center;

      a { line-height: 24px; }
      span[class*='icon-'] { line-height: 20px; }

      span.icon-Swim { color: $swimColor; }
      span.icon-Bike { color: $bikeColor; }
      span.icon-Run { color: $runColor; }
      span.icon-Other { color: $otherColor; }
    }
  }

  button.btn {
    min-width: 8rem!important;
  }
  button.available {
    background: $available;
    color: color-yiq($available)!important;
  }

  button.notAvailable {
    background: $notAvailable;
    color: color-yiq($notAvailable)!important;
  }

  .overlay {
    background-color: white;
    color: color-yiq(white);
    max-width: 70%;
    padding: 0.5em 1em;
    border: 1px solid #d4d4d4;
    white-space: pre-wrap;
  }

  table.availabilitiesGrid {
    width: 100%;

    &.overlain {
      filter: blur(1px);
    }

    th {
      cursor: cell;
      text-align: center;
      font-size: $font-size-sm;

      &:not(:first-child) {
        border-bottom: 1px solid $calendarBorderColor;
      }
    }

    td {
      height: 24px;
      line-height: 1;
      cursor: cell;
      border-right: 1px solid $calendarBorderColor;
      text-align: center;

      &:first-child {
        text-align: right;
        font-size: $font-size-xs;
        width: 1px;
        white-space: nowrap;
        padding: 0.125em 0.5em;
      }

      &.lastBlockInColumn {
        border-bottom: 1px solid $calendarBorderColor;
      }

      &.available {
        position: relative;

        // don't fill the entire cell background; leave some horizontal margin
        &:before {
          content: '';
          position: absolute;
          left: 3%;
          top: 0;
          width: 94%;
          height: 100%;
          background-color: $available;
        }

        div[class$="Bar"] {
          position: absolute;
          top: 0;
          width: 3px;
          height: 100%;
        }
        div.SwimBar {
          left: 6px;
          background-color: #019dd6;
        }
        div.BikeBar {
          left: 11px;
          background-color: rgba(#31c400, 1);
        }
        div.RunBar {
          left: 16px;
          background-color: rgba($runColor, 0.8);
        }
        div.OtherBar {
          left: 21px;
          background-color: rgba($otherColor, 0.8);
        }
      }

      &.notAvailable {
        background-color: $notAvailable;
      }
    }
  }
}

div.availabilitiesRangeSelection {
  position: absolute;
  pointer-events: none;
  border: 1px solid $primary;
  background-color: rgba($primary, 0.25);
}
