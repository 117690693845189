.rc-switch {
  position: relative;
  box-sizing: border-box;
  width: 40px;
  height: 20px;
  line-height: normal;
  vertical-align: middle;
  border-radius: 18px;
  @include gradient-bg(#b0b0b0);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch:after {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  background-color: #fff;
  content: " ";
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  transform: scale(1);
  transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-duration: 0.3s;
  animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
  transform: scale(1.125);
  animation-name: rcSwitchOn;
}
.rc-switch:focus {
  box-shadow: 0 0 2px 1px rgba(#000, 0.2);
  outline: none;
}
.rc-switch-checked {
  @include gradient-bg($primary);
}
.rc-switch-checked .rc-switch-inner {
  padding-left: 6px;
  padding-right: 24px;
}
.rc-switch-checked:after {
  left: calc(100% - 18px);
}
.rc-switch-disabled {
  cursor: not-allowed;
  background: #b0b0b0;
}
.rc-switch-disabled:after {
  background-color: #d8d8d8;
  animation-name: none;
  cursor: not-allowed;
}
.rc-switch-disabled:hover:after {
  transform: scale(1);
  animation-name: none;
}
@keyframes rcSwitchOn {
  0% { transform: scale(1); }
  100% { transform: scale(1.125); }
}
@keyframes rcSwitchOff {
  0% { transform: scale(1.125); }
  100% { transform: scale(1); }
}
