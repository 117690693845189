div.workoutStats > ul.nav-tabs > li.nav-item {
  flex: 1 1 0px;
  text-align: center;
}

div.workoutStats div.tab-pane {
  padding: 10px 0;
}

table.workoutMetrics {
  @include user-select;
  border: 0;
  font-size: $font-size-sm;
  margin: 0 auto;

  td:not(:first-child):not(:last-child),
  th:not(:first-child):not(:last-child) {
    padding-left: 1em;
  }
  td:last-child {
    padding-left: 0.25em;
  }

  td:not(:first-child):not(:last-child) {
    color: $highContrastColor; // discreet highlighting
  }
}

table.runEfficiencyMetrics {
  margin-top: 1em;

  td:nth-child(2) {
    padding-left: 0.5em;
  }
}

$lapsStatsBorderColor: $hr-border-color;
@if $theme != dark {
  $lapsStatsBorderColor: $alternatingTableRowBg;
}

div.lapsStats {
  @include user-select;
  font-size: $font-size-sm;
  overflow-x: auto;

  // all cells, incl. headers:
  > div > div > div {
    overflow-x: hidden;

    &:not(:last-child) {
      border-right: 1px solid $lapsStatsBorderColor;
    }
  }

  > div.thead {
    font-weight: bold;
    border-bottom: 1px solid $lapsStatsBorderColor;

    > div {
      line-height: 1.75em;

      // first header row
      &:nth-child(odd) {
        text-align: center;
      }

      // second header row
      &:nth-child(even) {
        text-align: right;

        > div {
          padding-right: 0.25em;

          > div {
            display: inline-block;
            min-width: 2em;
            text-align: center;
          }
        }
      }
    }
  }

  > div.tbody {
    max-height: 20em;
    overflow-y: auto;
  
    // rows
    > div {
      &:nth-child(even) {
        background-color: $alternatingTableRowBg;
      }

      // cells
      > div {
        text-align: right;
        padding: 0.1em 0.25em 0.1em 0;
      }
    }
  }
}
