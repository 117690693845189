.paymentSettings > div + div {
  margin-top: 1rem;
}

div.paymentCheckout {
  position: relative;
  max-width: 30rem;

  & button.buyNow {
    font-weight: $font-weight-bold;
  }
}

// Credit card page
div.creditCard {
  min-width: 20rem;

  & div.creditCardDetails {
    & div.l5c-field-label {
      width: 8rem;
    }
  }

  & div.updateCreditCard {
    & div.l5c-field-label {
      width: 100%;
    }
    & input[type="text"] {
      width: 100%;
    }
    & div.creditCardWrapper {
      height: 31.5px;
      padding-top: 8px;
    }
  }
}

// Billing address page
.billingAddressForm {
  min-width: 8rem;

  & div.l5c-field-label {
    width: 9rem;
  }

  & input[type="text"] {
    min-width: 8rem;
    width: 100%;
  }
}

//invoices page
div.invoices {
  & table {
    margin: 0 auto;

    & th, & td {
      padding-left: 3em;
      text-align: center;
    }
    & th:first-child, & td:first-child {
      padding-left: 0;
    }
    & th {
      font-weight: $font-weight-bold;
    }
  }
}

table.nextInvoiceSummary {
  margin-top: 0.5rem;

  td:first-child {
    min-width: 8rem;
    padding-right: 0.5em;
  }
  td:nth-child(2) {
    text-align: right;
  }
  td:nth-child(3) {
    padding-left: 0.5em;
  }
}

div.l5-invoice-modal {
  max-width: 600px;

  & table.invoiceDetails {
    width: 100%;
    font-family: monospace;

    & td:nth-child(2) {
      padding-left: 1rem;
      width: 90px;
      text-align: right;
    }
  }

  & table.invoicePayment {
    margin-top: 2rem;
    width: 100%;
    font-family: monospace;

    & td:nth-child(1) {
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
    }
    & td:nth-child(2) {
      padding-left: 1rem;
      width: 90px;
      text-align: right;
    }

    & hr {
      margin: 4px 0;
    }
  }

  & table.invoiceInfo {
    width: 100%;
    margin-bottom: 1rem;

    & td:nth-child(1) {
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
      width: 14rem;
    }
    & td:nth-child(2) {
      padding-left: 1rem;
      min-width: 90px;
      text-align: right;
    }
    tr.unpaidInvoice {
      color: $danger;
    }
  }
}

table.subscriptionInfo {
  width: 60%;
  margin-top: 1rem;
  margin-bottom: 1rem;

  td:first-child, td:nth-child(3) {
    font-weight: $font-weight-bold;
  }
}

.getPremium {
  background-color: white;
  margin: 1rem 0;
  padding: 5px;
  border: 1px solid #d4d4d4;
  white-space: pre-wrap;
  pointer-events: auto;
}

div.getPremiumOverlay > div.getPremium {
  max-width: 70%;
  margin: 0;
  padding: 0.5em 1em;

  @include media-breakpoint-down(md) {
    max-width: 75%;
    font-size: $font-size-sm;
  } 
}

.premiumDisabled {
  filter: blur(3px);
  pointer-events: none;
}
