div.external-connection {
  margin-bottom: 1rem;
  align-items: center;
  cursor: pointer;
  white-space: pre-wrap;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    cursor: default;
  }

  img.external-connection {
    width: 50px;
    filter: grayscale(50%);
    opacity: 0.67;
  }

  &.active {
    img.external-connection {
      filter: none;
      opacity: 1;
    }
  }

  @media (hover: hover) {
    &:hover img.external-connection {
      filter: none;
      opacity: 1;
    }
  }
}

.information {
  white-space: pre-wrap;
  font-weight: $font-weight-medium;
  font-size: $font-size-sm;
  margin-bottom: 20px;
}

.paymentSettings .mandate {
  margin: 1rem 0;
  font-size: 12px;
  white-space: pre-wrap;
}