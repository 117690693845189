$theme: light;

@import "external";
@import "bootstrap_light";
@import "all";

.alert-info {
  background: #33c6bf linear-gradient(180deg, lighten(#33c6bf,35%), lighten(#33c6bf, 40%)) repeat-x;
}

.modal-header {
  background-color: $body-bg;
}
