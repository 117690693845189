/* customIcons from Nicole */
@font-face {
  font-family: 'icomoon';
  src: url('/fonts/L5Icons/icomoon.eot'); /* IE9 Compat Modes */
  src: url('/fonts/L5Icons/icomoon.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/L5Icons/icomoon.woff') format('woff'), /* Modern Browsers */
       url('/fonts/L5Icons/icomoon.ttf') format('truetype'); /* Safari, Android, iOS */
}

span[class*='icon-'] {
  display: inline-block;
  font-family: 'icomoon';
  font-size: 16px;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span.icon-MultiSport {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;

  &:before {
    content: "\f0c1";
  }
}

.icon-distance:before {content: "\e900";}
.icon-arrow:before {content: "\e901";}
.icon-athlete-profile:before {content: "\e902";}
.icon-availability:before {content: "\e903";}
.icon-Competition:before {content: "\e915";}
.icon-CompetitionA:before {content: "\e915";}
.icon-CompetitionB:before {content: "\e915";}
.icon-CompetitionC:before {content: "\e915";}
.icon-calendar:before {content: "\e905";}
.icon-close:before {content: "\e906";}
.icon-Bike:before {content: "\e907";}           // custom name   cycle -> Bike
.icon-dashboard:before {content: "\e908";}
.icon-tss:before {content: "\e909";}
.icon-done:before {content: "\e90a";}
.icon-dropdown-down:before {content: "\e90b";}
.icon-dropdown-up:before {content: "\e90c";}
.icon-dropdown:before {content: "\e90d";}
.icon-duplicate:before {content: "\e90e";}
.icon-edit:before {content: "\e90f";}
.icon-expand:before {content: "\e910";}
.icon-facebook:before {content: "\e911";}
.icon-google+:before {content: "\e912";}
.icon-info:before {content: "\e913";}
.icon-plus:before {content: "\e914";}
.icon-Run:before {content: "\e904";}            // custom name   run -> Run
.icon-setting:before {content: "\e916";}
.icon-Swim:before {content: "\e917";}           // custom name   swim -> Swim
.icon-time:before {content: "\e918";}
.icon-trainer-profile:before {content: "\e919";}
.icon-upload:before {content: "\e91a";}
.icon-Other:before {content: "\e91b";}          // custom name   watch -> Other
.icon-trash:before {content: "\e920";} 

.icon-CompetitionA { color: $competitionColor; }
.icon-CompetitionB { color: #c0c0c0; }
.icon-CompetitionC { color: #9f7a34; }
