@include media-breakpoint-down(sm) {
  div.activityPage {
    background-color: $card-bg;
    margin: 0 !important;
    padding-top: 10px;

    .card {
      background-color: transparent;
      padding: 10px;
    }
  }
}

div.activityPage div.activity-with-workout-container {
  div.leftCol {
    flex: 1 1 30rem;
    min-width: 20rem;
    max-width: 40rem;
  }
  div.rightCol {
    flex: 1 1 40rem;
    min-width: 20rem;
    position: relative;

    div.mapping {
      flex: 1 1 0px;
      margin-bottom: 0.5rem;
      height: auto;
      min-height: 20rem;
      max-height: 30rem;
      padding: 0;
      z-index: 1;
    }

    div.workoutChart {
      flex: 1 1 0px;
      min-height: 20rem;
      max-height: 30rem;
    }
  }
}

div.previousNextBar {
  font-weight: $font-weight-medium;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  i {
    color: rgba($body-color, 0.5);
  }
  a i {
    color: $ctaColor;
    &:hover {
      color: $ctaColorHover;
    }
  }

  > :nth-child(2) {
    text-align: center;
    min-width: 15em;
    padding: 0 0.5em;
  }
}

.activityForm {
  max-width: 40rem;
  margin: 0 auto;

  & .l5c-field-label {
    width: 8rem;
  }

  & input[type=text]#actName {
    border-width: 0 !important;
    box-shadow: none !important;
    padding-left: 0;
    font-weight: $font-weight-medium;
    text-overflow: ellipsis;
    width: 100%;
  }

  & input[type=text].l5-date-input, & input[type=text].l5-time-input {
    border-style: none;
    box-shadow: none!important;
  }

  span[class*='icon-Competition'] {
    font-size: 20px;
    margin-right: calc(0.5rem + 4px); // to align the text with the athlete/author avatar text
  }

  .competitionLevel .dropdown-toggle:after {
    vertical-align: 0.5em; // for proper vertical centering with larger competition icon
  }

  table.splits {
    border: 0;
    margin-top: 0.75em;

    td {
      vertical-align: middle;

      &:first-child {
        padding-right: 1rem;
        text-align: center;
      }
      &.transitionDuration {
        line-height: 2;
        text-align: right;
        padding-right: 0.5em;
      };
      &:last-child {
        padding-left: 1rem;
        font-size: $font-size-sm;
      }
    }
  }

  & .plannedExecutedHeader {
    display: inline-block;
    width: 6rem;
    text-align: center;
    overflow-wrap: normal;
    font-weight: $font-weight-medium;
  }

  & .plannedExecuted {
    & input {
      width: 6rem;
    }

    & .input-group-text {
      font-weight: $font-weight-medium;
      background: transparent;
      border-style: none;
      box-shadow: none;
    }
  }

  & .rpeValue {
     i {
      font-size: 18px;
     }
     div.rpeSlider {
       padding-right: 2rem;
     }
  }

  & div.l5c-field-label.textareaLabel {
    align-self: flex-start;
    margin-top: 0.3em;
  }

  & textarea.minimalStyle {
    width: 100%;
    min-width: 8em;
    height: 30px;
  }

  button.openWorkoutEditorModal {
    min-width: 2.5rem;
    margin-left: 2px;
    > i {
      font-size: 14px;
    }
    @media (max-width: 399px) {
      min-width: 2rem!important;
      > i {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 399px) {
    .l5c-field-label {
      width: 5rem;
      overflow-wrap: normal;
    }
  
    .btn-group button {
      padding: 1px 4px;
    }
  
    .plannedExecutedHeader {
      width: 4.5rem;
    }
  
    .plannedExecuted {
      input {
        width: 4.5rem;
      }
    }
  }
}

.activityForm .l5c-field-label {
  overflow: hidden;
  text-overflow: ellipsis;
}

div.activity-form-container.card {
  display: block; // card defaults to flex
}

@include media-breakpoint-up(md) {
  div.activity-without-workout-container {
    margin: 0 auto;
    max-width: 44rem; // 40rem activity-form + 2x2rem padding

    div.activity-form-container.card {
      padding: 1rem 2rem;
    }
  }
}

.activityForm .PaceRecommendation {
  td:first-child {
    padding-right: 1rem;
  }
}

.childrenBar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0;
    padding: 5px 0 3px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  span[class*='icon-'] {
    font-size: 24px;
    line-height: 1;
  }

  > div {
    font-weight: $font-weight-medium;

    // the active icon (not inside <a> tag)
    > span[class*='icon-'],
    // or the active transition
    &.transition-arrow > i.fa-long-arrow-alt-right {
      text-decoration: underline;
    }
  }
}

div.readonlyActivityDescription {
  cursor: text;
  padding: 5px 9px; // like textarea padding

  &.placeholder {
    @if $theme == dark {
      color: $dropdown-link-disabled-color;
    } @else {
      color: #6c757d;
    }
  }

  &.readonly {
    cursor: default;
  }
}

div.editActivityDescription div.rta {
  position: relative;

  div.rta__autocomplete {
    position: absolute;
    z-index: 900;
    min-width: 200px;
    max-height: 162px; // 5 items + top/bottom border
    overflow-y: auto;
    background-color: $dropdown-bg;
    border: 1px solid $dropdown-border-color;
    @include box-shadow($dropdown-box-shadow);

    ul.rta__list {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0;
        margin: 0;

        div.rta__entity {
          display: flex;
          align-items: center;
          height: 32px;
          padding: 0 0.5em;
          font-size: $font-size-sm;

          &.rta__entity--selected {
            @if $theme == dark {
              background: #e3c812 linear-gradient(180deg,#cbb41a,#e3c812) repeat-x;
              color: #212529;
            } @else {
              background-color: #f0f8ff;
            }
          }
        }
      }
    }
  }
}

button#activityBurgerButton {
  min-width: 20px;
  margin-left: 0.5rem;
  border: none;
  box-shadow: none;
}

table.markdownInfo {
  th, div.variablesHeader {
    font-size: 18px;
  }
  tr {
    border-bottom: 1px solid rgb(82, 82, 82);
  }
  & > tbody > tr:last-child {
    border-bottom: 0;
  }
  td:first-child {
    padding-right: 1rem;
  }
  h1, h2, h3 {
    margin-bottom: 0;
  }
  ul, ol {
    margin-bottom: 0;
    padding-left: 24px;
  }

  div.variablesHeader {
    font-weight: $font-weight-bold;
    margin: 1rem 0 0.25rem;
  }
}

.leaflet-control-layers, .leaflet-bar {
  border: 0 !important;
  border-radius: 2px;
  @if $theme == dark {
    background: $secondary !important;
  } @else {
    background: #fff !important;
  }
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2) !important;
}

@if $theme == dark {
  // add a darkening overlay
  .leaflet-container:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.1);
    pointer-events: none;
  }
}

.leaflet-control-layers {
  color: inherit;

  > a.leaflet-control-layers-toggle {
    width: 30px;
    height: 30px;
    background-size: 20px 20px;
  }
}

.leaflet-bar > a {
  color: inherit;
  background-color: transparent !important;
  border-color: $hr-border-color !important;

  &:hover {
    color: $ctaColorHover;
  }
}

.leaflet-control-fullscreen a {
  @if $theme == dark {
    background-image: url('/img/leaflet-fullscreen-light.svg');
  } @else {
    background-image: url('/img/leaflet-fullscreen-dark.svg');
  }

  &:hover {
    background-image: url('/img/leaflet-fullscreen-cta.svg');
  }
}

button.react-share__ShareButton {
  margin: 5px 5px 10px 5px;
}

@media print {
  body, .card {
    background-color: white;
  }

  nav.navbar a.navbar-brand,
  nav.navbar ul.navbar-nav,
  div.pencil,
  span.icon-calendar,
  div.previousNextBar,
  div.alert.info,
  button.btn.btn-secondary,
  button.btn.btn-toggle, hr,
  a.nav-link,
  a.leaflet-control-zoom-in,
  a.leaflet-control-zoom-out,
  a.leaflet-control-fullscreen-button,
  div.leaflet-control-layers.leaflet-control,
  button.btn.l5-chart-button,
  span.icon-expand,
  div#workoutChart,
  div.leaflet-container {
    display: none;
  }

  button#activityBurgerButton {
    display: none!important;
  }

  a.nav-link.active {
    display: block;
  }

  button.btn.btn-secondary.active,
  button.btn.btn-toggle.active {
    color: rgb(53, 53, 53)!important;
    display: block;
  }

  button.btn.btn-secondary.active {
    border: none;
  }

  button.btn.btn-toggle.active {
    border: 1px solid rgb(170, 170, 170)!important;
    border-radius: 11px;
  }

  img.ppLogo.printView {
    text-align: right;
    display: block!important;
    height: 3rem;
  }

  div.activity-without-workout-container {
    margin: 0 auto 0 0;
    max-width: 40em;
  }

  textarea {
    resize: none;
  }

  .input-group-text {
    color: inherit;
  }
}
