/* From react-contextmenu examples (https://github.com/vkbansal/react-contextmenu/blob/master/examples/react-contextmenu.css). */

.react-contextmenu {
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: 1px solid $dropdown-border-color;
  // original: border-radius: .25rem;
  color: $dropdown-color;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  // original: padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
  // PerfectPace:
  z-index: 1000;
  @include box-shadow($dropdown-box-shadow);

  &.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    // original: z-index: 9999;
  }
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: $dropdown-color;
  cursor: pointer;
  font-weight: $font-weight-normal; // original: 400
  line-height: 1.5;
  padding: 3px 0.5rem; // original: 3px 20px
  text-align: inherit;
  white-space: nowrap;
  // PerfectPace:
  font-size: $font-size-sm;

  @media (max-width: 400px) {
    font-size: $font-size-xs;
  }

  // PerfectPace:
  &:focus {
    outline: 0;
  }

  &.react-contextmenu-item--active,
  &.react-contextmenu-item--selected {
    // original: color: #fff;
    color: color-yiq($highlightedBg);
    background-color: $highlightedBg; // original: #20a0ff
    border-color: $highlightedBg; // original: #20a0ff
    text-decoration: none;
  }

  &.react-contextmenu-item--disabled,
  &.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
  }
}

.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0,0,0,.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;

  &:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
  }
}

.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;

  > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
  }
}
