// similar to the leaflet styles
@mixin workout-chart-control-base {
  border-radius: 2px;
  width: 30px;
  @if $theme == dark {
    background-color: $secondary;
  } @else {
    background-color: #fff;
  }
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2) !important;
}

button.l5-chart-button {
  @include workout-chart-control-base;
  min-width: 0;
  pointer-events: auto;
  height: 30px;
  border: 0;

  &:hover {
    color: $ctaColorHover;
  }
}

div.workout-chart-slider-container {
  @include workout-chart-control-base;
  padding: 12px 0;
  flex: 0 1 124px; // max height 124px, shrinking enabled
  display: flex;
  justify-content: center;

  div.workout-chart-slider {
    pointer-events: auto;
    padding: 0 6px;
  }

  div.rc-slider-rail {
    width: 2px;
    background-color: #d0d0d0;
  }

  div.rc-slider-step {
    width: 2px;
  }

  span.rc-slider-dot {
    width: 6px;
    height: 6px;
    margin-left: -4px;
    border-color: #808080;

    &:first-child, &:last-child {
      margin-bottom: -3px;
    }
  }

  div.rc-slider-handle {
    margin-left: -6px;
    border-color: #808080;

    &:active, &:focus {
      border-color: $ctaColorHover;
      box-shadow: 0 0 5px $ctaColorHover;
    }
  }

  div.rc-slider-tooltip-inner {
    background-color: #000000cc; // 80% opacity
    padding: 5px;
    border-radius: 2px;
  }
}
