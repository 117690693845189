@media screen {
input[type=text], input[type=number], input[type=email], input[type=password], textarea, div.creditCardWrapper {
  box-shadow: $btn-box-shadow !important;
  border: solid 1px $input-border-color;
  @if $theme != dark {
    border-width: 1px 0 0 1px;
  }
  background-color: $input-bg;
  color: $input-color;
  font-weight: $input-font-weight;
  padding: 4px 8px;
  -webkit-appearance: none;
}
}

$invalidInputColor: mix($input-bg, $danger, 95%);
@if $theme == dark {
  $invalidInputColor: mix($input-bg, $danger, 75%);
}

// restrict red box shadow for invalid inputs to non-empty textboxes
// ('required' textboxes are invalid if empty)
input[type=text], input[type=email], input[type=password], textarea {
  &:not([value=""]):invalid {
    background-color: $invalidInputColor;
    box-shadow: inset 0px 0px 3px $danger !important;
  }
}
// invalid number fields apparently have an empty value
input[type=number]:invalid {
  background-color: $invalidInputColor;
  box-shadow: inset 0px 0px 3px $danger !important;
}

// tweak TextInput add-on labels
.input-group {
  flex-wrap: nowrap;
}
.input-group-text {
  padding: 0 0.5em;
  @if $theme == dark {
    background: linear-gradient(to right, rgba($input-bg, 1), rgba($input-bg, 0.5));
  } @else {
    background: linear-gradient(to right, rgba(#000, 0.025), rgba(#000, 0.0125));
  }
  border: solid 1px rgba(#000, 0.1);
  border-width: 1px 0 0 0;
  box-shadow: $btn-box-shadow;
}

textarea.minimalStyle {
  border-style: none;
  box-shadow: none!important;
}

div.l5-date-input {
  width: 8em;
}
div.l5-date-range-input {
  width: 13em;
}
div.l5-date-input, div.l5-date-range-input {
  position: relative;

  & input[type=text] {
    width: 100%;
  }

  & span.icon-calendar {
    position: absolute;
    right: 2px;
    top: 3px;
    pointer-events: none;
    color: rgba($input-color, 0.5);
  }
}

input[type=text].l5-time-input {
  width: 6em;
}
