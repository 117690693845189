.login .card {
  background-color: rgba(255, 231, 211, 0.9);
  padding: 8px;
}

.l5-login .btn:not(.btn-sm) {
  min-width: 215px;
  font-size: 1.1rem;
  font-weight: $font-weight-medium;

  img {
    width: 17px;
    height: 17px;
    margin-bottom: 2px;
  }
}
