.switchPlan {
  margin: 0 auto;
  max-width: 73rem;

  & .alert {
    margin-bottom: 1rem;
  }

  .subscriptions {
    justify-content: center;
    align-items: flex-end;

    .subscription {
      padding: 1.5rem 1rem 2rem;
      box-shadow: 0.5rem 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
      transition: box-shadow 0.5s;
      width: 23rem;
      text-align: center;
    }

    h3 {
      font-size: 2rem;
      font-style: italic;

      @media (max-width: 339px) {
        font-size: 1.8rem;
      }
    }

    h3.subscription-title {
      &:before, &:after {
        display: inline-block;
        background-color: #fff;
        content: '';
        mask-size: cover;
        height: 0.75em;
        width: 2.55em;
      }

      &:before {
        mask-image: url('/img/lines-left.svg');
        margin-right: 0.2em;
      }
      &:after {
        mask-image: url('/img/lines-right.svg');
        margin-left: 0.25em;
      }
    }

    .inAdditionTo {
      margin: 1.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6em;
    }

    .features {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 13em;
      border-color: #000;
      border-style: solid;
      border-width: 2px 0;
      margin-bottom: 1.5rem;
      font-weight: $font-weight-bold;
      font-style: italic;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li + li {
          margin-top: 0.5em;
        }
      }

      a {
        color: inherit;
        position: relative;

        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -1px;
          height: 2px;
          background-color: currentColor;
        }
      }
    }

    .btn-primary {
      min-width: 80%;
      padding: 0.5em 1em;
      font-size: 1.1rem;
      font-weight: $font-weight-bold;
      margin: 2rem 0 1rem;
    }

    .subscription.PP-Athlete-Free {
      background: #fff;
      color: #000;

      .subscription-title:before, .subscription-title::after {
        background-color: #000;
      }
    }

    .subscription.PP-Athlete-Premium {
      background: $primary;
      color: #000;

      .price { margin-top: 20px }
    }

    .subscription.PP-Athlete-Ultimate {
      background: #000;
      color: #fff;

      .price {
        margin-top: 40px;
        color: $primary;
      }

      .inAdditionTo {
        color: $primary;
      }

      .features {
        border-color: $primary;
        color: $primary;
      }
    }

    @media (max-width: 651px) {
      .inAdditionTo { height: auto; }
      .price { margin-top: 0 !important; }
    }
  }
}
