body:not(.shepherd-active) {
  .tippy-tooltip {
    max-width: 400px;

    @media (max-width: 431px) {
      max-width: calc(100vw - 32px); // leave space for vertical scrollbar etc.
    }
  }

  .tippy-content {
    text-align: left;
    font-size: $font-size-sm;

    p {
      margin-bottom: 0.75em;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.tippy-tooltip.activity-theme {
  min-width: 300px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: $dropdown-box-shadow;

  .tippy-content {
    font-size: $font-size-sm;
  }
}
