html {
  font-size: 0.9375rem; // ~15px

  @include media-breakpoint-down(sm) {
    font-size: 0.875rem; // ~14px
  }
}

html, body {
  height: 100%;
}

body > main {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  & > :first-child {
    flex: 1 0 auto;
  }
}

body {
  @include no-user-select;
}

div#mainFlexCol > :last-child {
  flex: 1 0 auto;
  margin: 10px;
}

div.withDashboardSettings > :nth-child(2) {
  flex: 1 0 auto;
}

div.flex-row {
  display: flex;
  flex-wrap: wrap;
}

div.flex-col {
  display: flex;
  flex-direction: column;
}

.smallText {
  font-size: $font-size-sm;
}
.tinyText {
  font-size: $font-size-xs;
}

.popover-font-size {
  font-size: $popover-font-size;
}

strong, .bold {
  font-weight: $font-weight-bold;
}

.leaflet-container {
  height: 500px;
  width: 100%;
}

// make sure the Leaflet fullscreen container is above the navigation bar
.leaflet-pseudo-fullscreen {
  z-index: 2000 !important;
}

div.workout-chart-with-map-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background-color: $card-bg;
}

.card {
  padding: 0.5rem;
}

.dropdown-item.active, .dropdown-item:active {
  color: color-yiq($dropdown-link-active-bg);
}

h2 {
  margin-bottom: 1.5rem;
}

h5 {
  font-weight: $font-weight-medium;
}

h5.pageTitle {
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

// for Firefox
a:focus {
  outline: 0;
}

a.actionLink {
  font-style: italic;
}

div.save-button {
  text-align: right;
  margin-top: 1rem;
}

a.magnifier-link {
  font-size: 16px;
  color: $infoColor;

  &:hover {
    color: $ctaColorHover;
  }
}

a.backlink-strava {
  color: #FC4C02;
}

div.statusPageContent {
  @if $theme == dark {
    background-color: darken($body-bg, 5%);
  } @else {
    background-color: #fff;
  }
  min-width: 320px;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  box-shadow: $box-shadow;
}

div.loadingOverlay {
  background-color: color-yiq($body-color, rgba(#000, 0.05), rgba(#fff, 0.2));
  font-size: 1rem;
}

span.icon-info {
  color: $infoColor;
  margin: 0 0.3rem;
}

.modal-header {
  align-items: center;
  border-bottom-color: $hr-border-color;

  .modal-title {
    text-transform: uppercase;
    font-weight: $font-weight-medium;

    span.info {
      position: relative;
      top: -2px;
    }
  }
  
  button.close {
    font-family: sans-serif;
    font-size: 1.75rem;
    padding: 0;
    margin: 0;
    color: $body-color;
    opacity: 1;

    &:not(:disabled):not(.disabled) {
      &:hover, &:focus {
        opacity: 0.75;
      }
    }
  }
}

div.collapsibleHeader {
  cursor: pointer;

  span.info, span[class*='icon-'] {
    line-height: 1;
  }
}

div.markdownContainer {
  white-space: pre-wrap; // keep whitespaces
  overflow-x: hidden; // prevent horizontal overflows for huge words

  p:last-child, ul:last-child, ol:last-child {
    margin-bottom: 0 !important;
  }

  ul, ol {
    padding-left: 24px;
  }

  img {
    max-width: 100%;
  }
}

i.far, i.fas { font-size: 16px; }

@include media-breakpoint-down(sm) {
  .hidden-on-mobile {
    display: none!important;
  }
}

@include media-breakpoint-up(md) {
  .hidden-on-desktop {
    display: none;
  }
}


// global option when printing

@media print {
  //don't print page header
  @page { 
    margin: 0 2cm;
  }
  
  body {
    color: rgb(53, 53, 53);
    font-weight: $font-weight-semibold;
  }

  .noPrint {
    display: none!important;
  }
}