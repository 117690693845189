g.chartAxis {
  font-size: $font-size-xs;

  @if $theme == dark {
    text { text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); }
  }
}

g.gridLines {
  stroke: rgba($body-color, 0.1);
}

text.barChartValue {
  fill: lighten(invert($secondary), 5%);
  text-shadow: 1px 1px 2px $secondary;
  font-size: $font-size-xs;
}

div.d3_l5_tooltip {
  position: absolute;
  pointer-events: none; // no mouseover etc. (interfering with mouseCatcher)
  background-color: rgba(#000, 0.8);
  transition: opacity 100ms ease-in-out;
  border-radius: $border-radius;
  padding: 3px;
  font-weight: $font-weight-semibold;
  z-index: 10;

  & div.d3_l5_tooltip_header {
    white-space: nowrap;
    font-size: $font-size-sm;
    color: #fff;
  }

  & table.d3_l5_tooltip {
    margin-left: 3px;

    & td {
      white-space: nowrap;
      font-size: $font-size-xs;
      text-align: right; // for numbers
      min-width: 3em;
      filter: brightness(150%);

      // 1st column: series name
      &:first-child {
        text-align: left;
        min-width: 0;
        color: #fff;
        font-weight: $font-weight-normal;
      }

      // last column: units
      &:last-child {
        text-align: left;
        min-width: 0;
      }
    }
  }
}
