// login, signup, init/logout, backend error/success...
div.standalone-page {
  position: relative;
  background: center / cover no-repeat url("/img/landingpage/Socialman2017SwimColor.jpeg?v=1");
  display: flex;
  align-items: center;
  justify-content: center;

  & img.pp-logo {
    position: absolute;
    top: 3rem;
    left: 3rem;
    height: 50px;
  }

  & div.pp-footer {
    position: absolute;
    right: 10px;
    bottom: 5px;

    & a {
      font-size: $font-size-xs;
      color: rgba(255, 255, 255, 0.94);
      text-transform: uppercase;
    }
  }

  & div.standalone-content {
    position: relative;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
    border: '2px solid $body-bg';
    background-color: $body-bg-transparent;

    // used for the static error/success pages
    & > div.alert {
      padding: 1rem;
      color: #000000;
      min-width: 300px;
      display: flex;
      align-items: center;

      &.alert-danger {
        border: 3px solid rgba($danger, 0.8);

        & i.fa-exclamation-circle {
          color: $danger;
          font-size: 2rem;
          margin-right: 1rem;
        }
      }

      &.alert-success {
        border: 3px solid rgba($success, 0.8);

        & i.fa-check {
          color: $success;
          font-size: 2rem;
          margin-right: 1rem;
        }
      }
    }
  }
}
