/* The following is a combination of the default theme from the shepherd with our bootstrap style.
 */
#shepherdModalOverlayContainer {
  -ms-filter: "progid:dximagetransform.microsoft.gradient.alpha(Opacity=50)";
  filter: alpha(opacity=50);
  height: 100vh;
  left: 0;
  opacity: .5;
  position: fixed;
  top: 0;
  transition: all .3s ease-out;
  width: 100vw;
  z-index: 9997;

  #shepherdModalMask,
  #shepherdModalMaskRect {
    height: 100vh;
    width: 100vw;
  }
}

.shepherd-modal.shepherd-enabled {
  position: relative;
  z-index: 9998
}

/**
 * Block clicks except for those that would occur
 * on Shepherd elements or on the target element.
*/
.shepherd-active {
  &.shepherd-modal-is-visible {
    :not(.shepherd-target) {
      pointer-events: none;
    }

    .shepherd-button,
    .shepherd-cancel-link,
    .shepherd-element,
    .shepherd-target {
      pointer-events: auto;

      * {
        pointer-events: auto;
      }
    }
  }
}

@media (max-width: 499px) {
  .tippy-popper.shepherd-popper {
    left: 20% !important;
    transform: translate(-12.5%, -50%) !important;
  }
}

@mixin popper-theme-arrows($arrow-size: 2.1, $arrow-pointer-events: null, $text-bg: $modal-content-bg, $header-bg: $body-bg) {
  $margin: calc((#{$arrow-size} / 2.1) * 16px);

  &[x-placement^='top'] {
    margin-bottom: $margin;

    .tippy-arrow {
      border-top-color: $text-bg;
    }

    .shepherd-has-title-theme {
      .tippy-arrow {
        border-top-color: $header-bg;
      }
    }
  }

  &[x-placement^='bottom'] {
    margin-top: $margin;

    .tippy-arrow {
      border-bottom-color: $text-bg;
    }

    .shepherd-has-title-theme {
      .tippy-arrow {
        border-bottom-color: $header-bg;
      }
    }
  }

  &[x-placement^='left'] {
    margin-right: $margin;

    .tippy-arrow {
      border-left-color: $text-bg;
    }

    &.shepherd-has-title-theme {
      .tippy-arrow {
        border-left-color: $header-bg;
      }
    }
  }

  &[x-placement^='right'] {
    margin-left: $margin;

    .tippy-arrow {
      border-right-color: $text-bg;
    }

    &.shepherd-has-title-theme {
      .tippy-arrow {
        border-right-color: $header-bg;
      }
    }
  }
}

.tippy-popper.shepherd-popper {
  @include popper-theme-arrows();

  max-width: 500px;
  z-index: 9999;

  .tippy-tooltip {
    background-color: transparent;
    max-height: 100%;
    max-width: 100%;
    padding: 0;
    text-align: initial;

    .tippy-arrow {
      transform: scale(1.5);
      z-index: 9999 + 1;
      pointer-events: none;
    }
  }
}

@mixin inline-block {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
}

@mixin shepherd-button {
  @include inline-block;

  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;

  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);
  @include button-variant($primary, $primary);

  @include button;
  @include primary-button;
}

.shepherd-element {
  outline: none;

  @include box-shadow($modal-content-box-shadow-xs);

  .shepherd-content {
    color: $body-color;
    background: $modal-content-bg;
    font-size: inherit;
    outline: 0;
    padding: 0;

    header {
      *zoom: 1;

      &:after {
        clear: both;
        content: '';
        display: table;
      }

      align-items: center;
      display: flex;
      justify-content: center;
      padding: $modal-header-padding;
      background-color: $body-bg;
      border-bottom: $modal-header-border-width solid $modal-header-border-color;

      .shepherd-title,
      .shepherd-cancel-link {
        margin: 0;
        padding: 0;
        position: relative;
        vertical-align: middle;
      }

      .shepherd-title {
        font-weight: $font-weight-medium;
        display: flex;
        flex: 1 0 auto;
        font-size: 1.25rem;
        line-height: 1.5;
      }

      .shepherd-cancel-link {
        font-size: 2rem;
        line-height: 1;
        margin-left: auto;
        text-decoration: none;
        color: lighten($body-color, 15%);

        &:before {
          font-family: sans-serif;
          font-weight: $font-weight-bold;
          content: "×";
        }

        &:hover {
          color: $body-color;
        }
      }
    }

    .shepherd-text {
      padding: 0.75em;
      font-size: 1rem;
      text-align: initial;

      a,
      a:visited,
      a:active {
        border-bottom: 1px dotted;
        text-decoration: none;

        &:hover {
          border-bottom-style: solid;
        }
      }

      p {
        margin-top: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    footer {
      display: flex;
      justify-content: flex-end;
      padding: 0 0.75em 0.75em;

      .shepherd-buttons {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: right;
      }

      .shepherd-button {
        @include shepherd-button;
        margin-left: 1rem;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }
}

.shepherd-element .shepherd-content footer .shepherd-button:last-child {
  margin-right: 0
}
