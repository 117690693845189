$todayColor: lighten($body-bg, 20%); // only used for dark theme

div.calendarSettings {
  flex: 0 0 auto;
  margin-bottom: 10px;

  & .btn {
    height: 35px;

    &#monthPickerButton {
      min-width: 9rem;
    }
  }
}

.stopScrolling {
  height: 100%;
  overflow: hidden;
}

div.l5c {
  border-bottom: 1px solid $calendarBorderColor;
  height: 100%; // required for older Safari
  cursor: default;
  // these 2 properties are essential for mobile devices!
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

div.l5c-row {
  // horizontal stacking:
  display: flex;
  flex-direction: row;

  // identical width for all <div> children of .l5c-rows based on available space...
  & > div {
    flex: 1 1 0px;
  }
  // ... except for the week summary
  & > div.l5c-week-summary {
    flex: 1 0 210px;
    max-width: 250px;
  }
}

div.l5c-header-row {
  cursor: default;
}

div.l5c-header-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 0.25em;
  padding-bottom: 0.5em;
  font-size: $font-size-sm;

  &.l5c-week-summary {
    font-weight: $font-weight-semibold;
    display: flex;
    align-items: center;

    .btn {
      margin-left: 0.5rem;
      min-width: initial;
      min-height: initial;
      width: 24px;
      height: 24px;
      padding: 0;

      &.active {
        background-color: $primary;
        color: color-yiq($primary);
      }

      i {
        line-height: 22px;
      }
    }
  }
}

div.l5c-week-row {
  border-top: 2px solid $calendarBorderColor;
  overflow: hidden; // required for Firefox, otherwise rows overflow
  @if $theme != dark {
    background-color: #fff;
  }

  &.isCurrentWeek {
    @if $theme == dark {
      background: linear-gradient(to bottom, $body-bg, $todayColor);
    } @else {
      background: linear-gradient(to bottom, #fff, #e6f4f9);
    }
  }

  &.isTrainingWeek { border-top-color: $trainingWeekColor; }
  &.isRecoveryWeek { border-top-color: $recoveryWeekColor; }
  &.isTaperingWeek { border-top-color: $taperingWeekColor; }

  & > div {
    border-left: 1px solid $calendarBorderColor;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 12rem;
  }

  & > div.l5c-day-cell {
    // make the single child (a div generated by <ContextMenuTrigger>) full height (Safari 9...)
    display: flex;
    & > :first-child {
      flex: 1 1 0px;
    }

    &.l5c-today-cell {
      @if $theme == dark {
        background-color: $todayColor;
      } @else {
        background: linear-gradient(to bottom, #fff, #d0e9f2);
      }
    }
  }
}

div.l5c-day-header {
  background-color: darken($body-bg, 2%);
  border-bottom: 1px solid darken($body-bg, 20%);
  display: flex;
  align-items: center;
  padding: 1px 0.5rem;

  @media (max-width: 400px) {
    font-size: $font-size-xs;
    padding: 1px 0.25rem;
  }

  div.l5c-date-caption {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  div.l5c-hamburger {
    margin-left: auto;
    margin-bottom: -0.5em;
  }
}

div.l5c-event-content {
  margin: 0 1px 1px 1px;
  border-radius: 4px;
  padding: 1px 5px;
  background: linear-gradient(to bottom, $activityItemBg, lighten($activityItemBg, 5%) 80px);
  border: 1px solid $activityItemBorderColor;
  cursor: pointer;

  &.isDraft {
    @if $theme == dark {
      background: linear-gradient(to bottom, #fff8f8, #ffd8d8 80px);
    } @else {
      background: #fff;
    }
    border: 1px dashed red;
    color: $yiq-text-dark;
  }

  &.daily-comment {
    background: #efefef;
    border: 1px solid #d3d3d3;
    color: $yiq-text-dark;

    span.fa-comment-dots {
      color: #5f5f5f;
      font-size: 14px;
      padding-left: 6px;
    }
  }

  @if $theme == dark {
    &.isCompleted {
      background: linear-gradient(to bottom, $isCompleted, lighten($isCompleted, 5%) 80px);
      border-color: darken($isCompleted, 20%);
    }
    &.isCompletedOff {
      background: linear-gradient(to bottom, $isCompleted, lighten($isCompletedOff, 5%) 80px);
      border-color: darken($isCompletedOff, 20%);
    }
    &.isCompletedWayOff {
      background: linear-gradient(to bottom, $isCompleted, lighten($isNotCompleted, 5%) 80px);
      border-color: darken($isCompletedOff, 20%);
    }
    &.isNotCompleted {
      background: linear-gradient(to bottom, $isNotCompleted, lighten($isNotCompleted, 5%) 80px);
      border-color: darken($isNotCompleted, 10%);
    }
    &.isCompetition {
      background: linear-gradient(to bottom, $primary, lighten($primary, 10%) 80px);
      border-color: darken($primary, 5%);
      color: $yiq-text-dark;
    }
  } @else {
    &.isCompleted {
	    background: linear-gradient(to bottom, lighten($isCompleted, 10%), lighten($isCompleted, 23%) 80px);
	    border-color: $isCompleted;
    }
    &.isCompletedOff {
      background: linear-gradient(to bottom, $isCompleted, lighten($isCompletedOff, 5%) 80px);
      border-color: darken($isCompleted, 20%);
    }
    &.isCompletedWayOff {
      background: linear-gradient(to bottom, $isCompleted, lighten($isNotCompleted, 5%) 80px);
      border-color: darken($isCompleted, 20%);
    }
	  &.isNotCompleted {
	    background: linear-gradient(to bottom, lighten($isNotCompleted, 15%), lighten($isNotCompleted, 23%) 80px);
	    border-color: lighten($isNotCompleted, 10%);
	  }
	  &.isCompetition {
	    background: linear-gradient(to bottom, lighten($primary, 10%), lighten($primary, 35%) 80px);
	    border-color: $primary;
	  }
  }

  > div.header {
    display: flex;
    align-items: center; // vertically center all children

    div.l5c-event-icon {
      flex: 0 0 auto; // leave its size alone!
      border-radius: 13px;
      background-color: #fff;
      width: 26px;
      height: 26px;
      margin-right: 0.5rem;

      span[class*="icon-"] {
        width: 26px;
        line-height: 27px; // apparent Firefox bug
      }

      span.icon-Swim {
        font-size: 20px;
        color: $swimColor;
      }
      span.icon-Bike {
        font-size: 18px;
        color: $bikeColor;
      }
      span.icon-Run {
        font-size: 18px;
        color: $runColor;
      }
      span.icon-Other {
        font-size: 18px;
        color: $otherColor;
      }
      span[class*='icon-Competition'] {
        font-size: 16px;
      }
      span.icon-MultiSport {
        font-size: 14px;
        color: $multiSportColor;
      }
    }

    div.l5c-event-caption {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: $font-size-sm;
      flex: 1 1 0px;

      @media (max-width: 399px) {
        display: none;
      }
    }

    > i.fa-exclamation-triangle {
      margin-left: 2px;
    }

    div.l5c-comment-icon {
      flex: 0 0 auto; // leave its size alone!
      padding: 0 2px;
      margin-left: 2px;
      color: white;

      &.rating-green { color: $ratingGreen; }
      &.rating-yellow { color: $ratingYellow; }
      &.rating-red { color: $ratingRed; }

      > i { -webkit-text-stroke: 1px #7a7a7a; }

      @media (max-width: 499px) {
        display: none;
      }
    }
  }

  > div.details {
    > div.header {
      font-size: $font-size-sm;
      font-weight: $font-weight-medium;
      line-height: 1;
      margin: 4px 0 6px;
      padding-top: 4px;
      border-top: 1px solid rgba($body-color, 0.1);
    }

    > div.metrics {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      font-size: $font-size-xs;
      margin-left: -1em;

      // DetailsItem
      > div {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-left: 1em;
        line-height: 1.33;

        // icon
        > div:first-child {
          //color: #808080;
          opacity: 0.8;
        }
        // text
        > div:nth-child(2) {
          min-width: 26px;
          text-align: right;
          margin-right: 1px;
        }

        // TRIMP number
        &:last-child > div:nth-child(2) {
          min-width: 22px;
          margin-right: 0;
        }
      }
    }
  }

  & > div.l5c-event-caption {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: $font-size-sm;
  }
}

div.l5c-day-cell div.availability {
  width: 2px;

  & div.available {
    background-color: darken($available, 35%);
  }
  & div.notAvailable {
    background-color: $notAvailable;
  }
}
@if $theme == dark {
  div.l5c-day-cell:hover div.availability div.available {
    background-color: lighten($isCompleted, 5%);
  }
}

div.l5c-week-row > div.l5c-week-summary {
  overflow-y: visible;

  & div.mesotype {
    padding-left: 1rem;
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;

    &.isTrainingWeek { color: $trainingWeekColor; }
    &.isRecoveryWeek { color: $recoveryWeekColor; }
    &.isTaperingWeek { color: darken($taperingWeekColor, 10%); }
  }

  span.icon-Swim { color: $swimColor; }
  span.icon-Bike { color: $bikeColor; }
  span.icon-Run { color: $runColor; }
  span.icon-Other { color: $otherColor; }

  // percentage indicators
  i.fa-check { font-size: 10px; }
  span.icon-dropdown-down, span.icon-dropdown-up { font-size: 12px; }

  & > table {
    @include user-select;
    margin: 0.5rem auto 0 auto;
    font-size: $font-size-sm;

    & th {
      font-size: 16px;
      line-height: 1;
      text-align: right;
      font-weight: normal; // no bold icons
    }

    & tr:nth-child(even) {
      background-color: $alternatingTableRowBg;
    }

    // numbers
    & td:nth-child(even) {
      min-width: 3em;
      text-align: right;
    }

    // sport icon / percentage indicators
    & td:nth-child(odd) {
      min-width: 16px;
    }

    // last row (totals)
    & tr:last-child {
      & td {
        border-top: 1px solid $calendarBorderColor;
      }
    }
  }
}
.wrapperOfMysteryBlock>div {
  top: 40px!important;
}
.trainerWrapperOfMysteryBlock>div {
  top: 80px!important;
}

.sidebarContent {
  background: $body-bg;
  width: 100%;
  padding: 5px;
  bottom: 145px !important;
}
.sidebarOverlay {
  bottom: 152px !important;
  top: 93px !important;
  left: 6px !important;
  right: 6px !important;
}
.trainerSidebarOverlay {
  bottom: 152px !important;
  top: 133px !important;
  left: 6px !important;
  right: 6px !important;
}

div.sidebarContent > div.templatesLibrary.flex-col > div.card > div > div.l5c-event-content > div.header > div.l5c-event-caption {
  @media (max-width: 399px) {
    display: block;
  }
}

@include media-breakpoint-down(md) {
  div.l5c-week-summary {
    display: none !important;
  }
}
