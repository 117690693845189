.templatesLibrary {
  height: 100%;

  &.templatesLibrary-withSeasonPlan {
    height: calc(100% - 1.5rem - 26px); // subtract height of season plan

    @include media-breakpoint-down(lg) {
      height: calc(100% - 1.5rem - 20px); // different mesoplan height
    }
  }

  h5 {
    font-size: $font-size-sm;
    font-weight: $font-weight-semibold;
  }

  div.libraryAndCategory {
    flex: 0 0 auto; // take up exact height
    margin-top: 4px;
    display: flex;
    align-items: center;
  
    // library & category dropdown toggles
    > div.library, > div.category {
      flex: 1 0 0px;
      min-width: 0px;
      max-width: 150px;

      .dropdown-toggle {
        min-width: 0;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-left: 0.1875em;
        padding-right: 0.1875em;
      };

      // headers
      span.tinyText {
        color: lighten($body-color, 33%);
      }
    }

    // separators
    > div:nth-child(even) {
      flex: 0 0 1px;
      align-self: stretch;
      background-color: rgba($highContrastColor, 0.03);
      margin: 4px 2px;
    }

    > div.manageCategories {
      padding-left: 0.1875em;
      padding-top: 7px;
      a:not(:hover) { color: $body-color; }
    }
  }

  input#searchString {
    width: calc(100% - 1px);
    font-size: $font-size-sm;
    margin: 4px 0;
  }

  .card {
    flex: 1 1 0px;
    padding: 1px 0 0 0;
    overflow: auto;
  }

  div.l5c-event-content {
    background: $activityItemBg; // no gradient
  }

  div.templateDetails {
    border: 1px solid $activityItemBorderColor;
    border-width: 0 1px 1px 1px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin: 0 1px;
    padding: 0.25em;
    font-size: $font-size-sm;
    background: linear-gradient(to bottom, $activityItemBg, lighten($activityItemBg, 5%) 80px);

    table {
      margin-bottom: 0.5em;

      // icons
      td:nth-child(1) {
        font-size: 16px;
        text-align: center;
      }
    
      // numbers
      td:nth-child(2) {
        text-align: right;
        padding: 0 0.25em 0 1em;
        min-width: 4em;
      }
    }

    button.btn {
      min-width: 34px;
    }
  }

  div.l5c-event-content.addActivityTemplate {
    cursor: pointer;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    @if $theme != dark {
      background: linear-gradient(to bottom, #fff, lighten($activityItemBg, 7%));
      border: solid 1px $activityItemBg;
    }

    &.disabled {
      cursor: not-allowed;
    }

    &:not(.disabled):hover {
      @if $theme == dark {
        background: rgba(#000, 0.1);
      } @else {
        background: lighten($activityItemBg, 7%);
      }
    }
  }
}

div.modal-dialog.TrainingCategories {
  div.newCategory {
    margin: 0.5rem 0;
    align-items: center;

    // textbox
    > div:nth-child(1) {
      flex: 1 1 0px;
      input { width: 100%; }
    }

    // button
    > div:nth-child(2) {
      flex: 0 0 auto;
      margin-left: 0.5rem;
      button { min-width: 5rem; }
    }
  }

  div.categoriesContainer {
    border: 1px solid $alternatingTableRowBg;

    div.flex-row {
      align-items: center;
      padding: 0.25em 0;

      &:nth-child(even) {
        background: $alternatingTableRowBg;
      }

      // pencil/info icon
      > div:nth-child(1) {
        flex: 0 0 2rem;
        text-align: center;

        .info { margin-left: 0; }
        i { font-size: 14px; }
      }

      // textbox
      > div:nth-child(2) {
        flex: 1 1 0px;

        input {
          color: $body-color;
          background: transparent;
          border: 0;
          box-shadow: none !important;
          padding: 0;
        }
      }

      // remove icon
      > div:nth-child(3) {
        flex: 0 0 auto;
        margin: 0 0.5em;

        a:not(:hover) { color: $body-color; }
        i { font-size: 14px; }
      }
    }
  }
}