div.l5-date-picker, div.l5-week-picker {
  width: 16em;

  & div.navChevron {
    padding: 0.125em 1em;
    font-size: 1.333rem;
    color: $ctaColor;
    cursor: pointer;

    &:hover {
      color: $ctaColorHover;
    }
  }

  & div.month {
    text-align: center;
  }

  & div.dayHeader {
    padding: 0.25em;
    text-align: center;
  }

  & div.day {
    padding: 0.25em;
    text-align: center;
    cursor: pointer;

    &.grayed-out {
      color: #aaa;
    }
  }

  div.day, div.week {
    &:hover, &.highlighted {
      background-color: $highlightedBg;
      color: color-yiq($highlightedBg);
    }
  }
}

div.l5-date-picker div.day.selected, div.l5-week-picker div.day.selected, div.l5-week-picker div.day.selected ~ div.day {
  background-color: $component-active-bg;
  color: color-yiq($highlightedBg);
}
