div.workoutEditor {
  div.step {
    &:not(.repeatStep-level0) {
      padding: 2px 4px;
      margin-bottom: 0.25em;
      border: 1px solid $calendarBorderColor;
      border-radius: $border-radius;
    }

    button.btn, .dropdown-menu {
      min-width: auto;
    }
    button.addButton {
      min-width: 80px;
    }

    input { width: 5em; }
  }

  div.regularStepHeader, div.repeatStepHeader {
    flex-wrap: nowrap;
    align-items: center;

    > div.actions {
      margin-left: 2em;
      flex: 1 1 0px;
      text-align: right;

      .btn + .btn {
        margin-left: 0.25em;
      }
    }
  }

  div.repeatStep:not(.repeatStep-level0) > div.repeatStepChildren {
    margin: 0.25em 0 0.25em 2em;
  }

  div.repeatStepHeader {
    input[type='number'] { margin: 0 0.75em; }
  }

  div.regularStepHeader {
    > div.intensity {
      min-width: 8em;
      button.dropdown-toggle { padding-left: 0; }
    }

    > div.durationSelection {
      margin-left: 2em;
    }
    > div.durationValue {
      margin-left: 0.25em;
      width: 10em;
    }

    > div.targetCategory {
      margin-left: 2em;
    }
    > div.targetClass {
      margin-left: 0.25em;
    }
    > div.targetValue {
      margin-left: 0.25em;
    }
  }

  div.header {
    margin-bottom: 0.25em;
  }
  div.compatBox {
    text-align: right;
    padding: 3px;
  }
  div.metricsPreview {
    margin-bottom: 0.5em;
    justify-content: center;
    > div {
      display: flex;
      align-items: center;
      > div:first-child {
        margin-right: 0.25em;
      }
    }
  }
  div.description {
    margin-top: 0.25em;
  }
  div.header, div.description {
    textarea {
      width: calc(100% - 1px);
    }
  }
}
