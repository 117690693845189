.info {
  margin-left: 0.25em;
  font-size: 16px;
}

$pwaBackgroundColor: #c5c5c5;
$pwaBorderColor: #999999;

.pwaPopup.iPhone, .pwaPopup.iPod, .pwaPopup.iPad {
  width: 290px;
  position: fixed;
  z-index: 10000;
  background-color: $pwaBackgroundColor;
  border-radius: 10px;
  border: 1px solid $pwaBorderColor;
  padding: 15px;

  div.closeIcon {
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
  }
}

.pwaPopup.iPhone {
  bottom: 25px;
  left: 50%;
  margin-left: -145px;

  &:before {
    left: 120px;
    bottom: -50px;
    border: 25px solid;
    border-color: $pwaBorderColor transparent transparent transparent;
  }

  &:after {
    left: 121px;
    bottom: -48px;
    border: 24px solid;
    border-color: $pwaBackgroundColor transparent transparent transparent;
  }
}

.pwaPopup.iPad {
  top: 25px;
  right: 25px;

  &:before {
    left: 155px;
    top: -50px;
    border: 25px solid;
    border-color: transparent transparent $pwaBorderColor transparent;
  }

  &:after {
    left: 156px;
    top: -48px;
    border: 24px solid;
    border-color: transparent transparent $pwaBackgroundColor transparent;
  }
}